import React from 'react';
import { Provider } from 'react-redux';

import { applyMiddleware, compose, createStore } from 'redux';

import { ConnectedRouter, routerMiddleware } from 'connected-react-router';

import { history } from '../routes';
import reducers from './reducers';

/**
 * Middleware
 */
export const middleware = applyMiddleware(...[
  routerMiddleware(history)
]);

/**
 * The store
 */
const store = createStore(reducers, undefined, compose(middleware));

/**
 * Wrap in Provider
 */
export function wrap (children) {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        {children}
      </ConnectedRouter>
    </Provider>
  );
}

export const dispatch = store.dispatch;

export default store;
