import { lazy } from 'react';

export default [
  {
    path: '/',
    component: lazy(() => import('components/views/login/Login')),
    title: 'Login',
    exact: true
  },
  {
    path: '/start',
    component: lazy(() => import('components/views/start/Start')),
    title: 'Start',
    exact: true
  },
  {
    path: '/contacts',
    component: lazy(() => import('components/views/contacts/Contacts')),
    title: 'Contacts',
    exact: true
  },
  {
    path: '/results',
    component: lazy(() => import('components/views/results/Results')),
    title: 'Results',
    exact: true
  },
  {
    path: '/tips',
    component: lazy(() => import('components/views/tips/Tips')),
    title: 'Tips',
    exact: true
  },
  {
    path: '/info',
    component: lazy(() => import('components/views/info/Info')),
    title: 'Info',
    exact: true
  }
];
