import React, { Component } from 'react';
import shuffle from 'lodash/shuffle';
import PoweredBy from 'components/general/poweredBy/PoweredBy';
import Gear from 'components/general/svg/Gear';
import strings from 'locale/fi.json';
import './Computing.scss';

export default class Computing extends Component {
  render () {
    const texts = [];
    let key = 1;
    for (const loadingText of shuffle(strings['loading-texts'])) {
      texts.push(<li key={key++} className='slide showing'>{loadingText.text}</li>);
      break;
    }

    return (
      <div className='computing-container fade-animation'>
        <div className='computing-gears'>
          <div className='gear gear-1'>
            <Gear />
          </div>
          <div className='gear gear-2'>
            <Gear />
          </div>
          <div className='gear gear-3'>
            <Gear />
          </div>
        </div>
        <div className='loading-text' id='loading-text'>
          <ul id='slides'>
            {texts}
          </ul>
        </div>
        <div className='powered-by-container footer-powered-by'>
          <PoweredBy />
        </div>
      </div>
    );
  }
}
