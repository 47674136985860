import React, { Component } from 'react';

export default class Gear extends Component {
  render () {
    return (
      <svg xmlns='http://www.w3.org/2000/svg' width='77' height='81' viewBox='0 0 77 81'>
        <path fillRule='evenodd' d='M44.533 0c2.268 0 3.767 1.889 4.123 4.132l.911 5.746c3.622 1.317 6.942 3.264 9.834 5.703l5.428-2.085c2.116-.812 4.497-.456 5.629 1.513l6.035 10.482c1.134 1.966.251 4.214-1.509 5.645l-4.51 3.668c.327 1.85.496 3.754.496 5.696 0 1.945-.169 3.846-.495 5.696l4.51 3.668c1.76 1.43 2.642 3.679 1.508 5.645l-6.035 10.482c-1.132 1.969-3.513 2.325-5.629 1.513l-5.428-2.085c-2.892 2.441-6.212 4.386-9.834 5.703l-.911 5.746C48.3 79.111 46.8 81 44.533 81H32.466c-2.266 0-3.765-1.889-4.12-4.132l-.914-5.746c-3.62-1.32-6.942-3.262-9.831-5.703l-5.429 2.085c-2.118.812-4.497.456-5.63-1.513L.505 55.509c-1.131-1.966-.251-4.214 1.509-5.645l4.51-3.668c-.325-1.848-.496-3.751-.496-5.696 0-1.942.171-3.846.495-5.696l-4.51-3.668c-1.76-1.43-2.64-3.679-1.508-5.645l6.035-10.482c1.134-1.969 3.513-2.325 5.631-1.513l5.429 2.085c2.889-2.441 6.211-4.386 9.83-5.703l.915-5.746C28.7 1.889 30.2 0 32.466 0h12.067zm-5.532 28C32.372 28 27 33.597 27 40.5S32.372 53 39.001 53C45.628 53 51 47.403 51 40.5S45.628 28 39.001 28z' />
      </svg>
    );
  }
}
