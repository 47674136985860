import trimStart from 'lodash/trimStart';
import axios from 'axios';

import config from 'config';
import { bind } from 'utils';

/**
 * API methods
 */
export default class APIRequest {
  constructor () {
    bind(this, [
      'delete',
      'get',
      'post',
      'put',
      'request'
    ]);
  }

  /**
   * Get root
   */
  get root () {
    return config.api.url || '';
  }

  /**
   * Delete
   */
  delete (endpoint) {
    return this.request('delete', endpoint);
  }

  /**
   * Get
   */
  get (endpoint) {
    return this.request('get', endpoint);
  }

  /**
   * Post
   */
  post (endpoint, data) {
    return this.request('post', endpoint, data);
  }

  /**
   * Put
   */
  put (endpoint, data) {
    return this.request('put', endpoint, data);
  }

  /**
   * Perform request
   */
  request (method, endpoint, data = {}, headers = {}) {
    const args = {
      url: this.root + trimStart(endpoint),
      method: method,
      headers: headers
    };

    if (['get'].indexOf(method) >= 0) {
      args.params = (data);
    }

    if (['post', 'put'].indexOf(method) >= 0) {
      args.data = (data);
    }

    if (config.api.token) {
      args.headers['X-API-KEY'] = config.api.token;
    }

    return axios(args).then(response => response.data).catch(exception => Promise.reject(exception));
  }
}
