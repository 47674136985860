import trimEnd from 'lodash/trimEnd';
import trimStart from 'lodash/trimStart';

import React from 'react';
import { createBrowserHistory } from 'history';
import { Route } from 'react-router-dom';

import routeList from './routes';

/**
 * Browser history
 */
export const history = createBrowserHistory();

/**
 * Get route
 */
export function route (item) {
  if (!item.component) {
    item = {
      component: item
    };
  }
  const routes = (item.routes || []).map((subroute) => {
    const subpath = trimStart(subroute.path, '/');
    const path = trimEnd(item.path, '/') + (subpath ? ('/' + subpath) : '');
    return {
      ...subroute,
      path
    };
  });
  return (
    <Route
      key={item.path}
      exact={item.exact !== false}
      path={item.path}
      render={(props) => {
        return <item.component {...props} routes={routes} />;
      }}
    />
  );
}

/**
 * Get routes
 */
export function routes (list) {
  return list.map(route);
}

export default routeList;
