import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bind } from 'utils';
import { history } from 'routes';
import APIRequest from 'api/APIRequest';
import Computing from 'components/general/computing/Computing';
import PoweredBy from 'components/general/poweredBy/PoweredBy';
import SubmitButton from 'components/general/submitButton/SubmitButton';
import Back from 'components/general/svg/Back';
import Chat from 'components/general/svg/Chat';
import TextArea from 'components/general/textArea/TextArea';
import TextBox from 'components/general/textBox/TextBox';
import PropTypes from 'prop-types';
import strings from 'locale/fi.json';
import './PageBar.scss';

class PageBar extends Component {
  constructor (...args) {
    super(...args);
    this.state = {
      toggle: false,
      processing: false,
      success: false,
      name: '',
      org: '',
      email: '',
      phone: '',
      feedback: '',
      errors: {
        name: '',
        org: '',
        email: '',
        phone: '',
        feedback: ''
      }
    };

    bind(this, [
      'onClick', 'onReload', 'handleChange', 'validate', 'validateForm', 'onToggle', 'sendFeedback', 'onClickFeedback', 'onClickTips'
    ]);
  }

  onClickFeedback () {
    this.setState({ toggle: !this.state.toggle });
  }

  onClickTips () {
    if (history.location.pathname !== '/tips') {
      history.push('/tips');
    }
  }

  validate (name, value) {
    return new Promise(resolve => {
      let errorVal = '';
      // eslint-disable-next-line
      const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

      switch (name) {
        case 'name':
          errorVal =
            value.length < 1
              ? strings['error-val-name-required'] : '';
          break;
        case 'org':
          errorVal =
            value.length < 1
              ? strings['error-val-org-required'] : '';
          break;
        case 'phone':
          errorVal =
            value.length < 1
              ? strings['error-val-phone-required'] : '';
          errorVal =
            isNaN(value)
              ? strings['error-val-phone-invalid'] : errorVal;
          break;
        case 'email':
          errorVal =
            validEmailRegex.test(value)
              ? ''
              : strings['error-val-email-not-valid'];
          errorVal =
            value.length === 0
              ? errorVal = strings['error-val-email-required']
              : errorVal;
          break;
        case 'feedback':
          errorVal =
            value.length < 1
              ? strings['error-val-feedback-required'] : '';
          break;
        default:
          break;
      }

      this.setState(state => {
        return {
          errors: {
            ...state.errors,
            [name]: errorVal
          },
          [name]: value
        };
      }, () => resolve());
    });
  }

  async validateForm () {
    let valid = true;
    await this.validate('name', this.state.name);
    await this.validate('org', this.state.org);
    await this.validate('phone', this.state.phone);
    await this.validate('email', this.state.email);
    await this.validate('feedback', this.state.feedback);

    Object.values(this.state.errors).forEach(
      (val) => val.length > 0 && (valid = false)
    );
    return valid;
  }

  handleChange (e) {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.validate(name, value);
    this.setState({ [name]: value });
  }

  onToggle () {
    this.setState({ toggle: !this.state.toggle });
  }

  onReload () {
    this.setState({
      success: false,
      name: '',
      org: '',
      email: '',
      phone: '',
      feedback: ''
    });
  }

  async onClick () {
    if (await this.validateForm()) {
      this.setState({ processing: true });
      await new Promise(resolve => setTimeout(resolve, strings.timeout));
      this.sendFeedback();
    }
  }

  async sendFeedback () {
    try {
      const data = {
        feedback: {
          user: {
            name: this.state.name,
            organization: this.state.org,
            email: this.state.email,
            phone: this.state.phone
          },
          text: this.state.feedback
        }
      };
      this.api = new APIRequest();
      await this.api.request('post', 'companionFeedback', data);
      this.setState({ processing: false });
      this.setState({ success: true });
    } catch (error) {
      this.setState({ processing: false });
    }
  }

  static get propTypes () {
    return {
      user: PropTypes.object
    };
  }

  render () {
    if (!this.props?.user?.info?.loginCompany) {
      return (<></>);
    }

    return (
      <div className='page-bar-container fade-animation'>
        <div className='top-bar-container'>
          <Chat /><span onClick={() => this.onClickTips()}>{strings['send-a-tip']}</span>{strings.or}<span onClick={() => this.onClickFeedback()}>{strings['leave-feedback']}</span>
        </div>
        <div className='feedback-drawer-container'>
          <div id='drawer-toggle-container' className={this.state.toggle ? 'toggle-drawer' : ''}>
            <div id='drawer'>
              {this.state.success === true &&
                <div className='feedback-form-container feedback-success'>
                  <h1 className='page-header'>{strings['feedback-sent']}</h1>
                  <SubmitButton label={strings['back-link']} handleClick={this.onReload} />
                </div>}
              {this.state.success === false && this.state.processing === true && <div className='feedback-computing feedback-powered-by'><Computing /></div>}
              {this.state.success === false && this.state.processing === false &&
                <div className='feedback-form-container fade-animation'>
                  <div className='main-form'>
                    <div className='back-img' onClick={() => this.onToggle()}>
                      <Back /> <span>{strings['back-link']}</span>
                    </div>
                    <h1 className='page-header'>{strings['feedback-header']}</h1>
                    <TextBox label={strings['input-name-2']} name='name' type='text' value={this.state.name} onHandleChange={this.handleChange} errors={this.state.errors} />
                    <TextBox label={strings['input-org']} name='org' type='text' value={this.state.org} onHandleChange={this.handleChange} errors={this.state.errors} />
                    <TextBox label={strings['input-email']} name='email' type='email' value={this.state.email} onHandleChange={this.handleChange} errors={this.state.errors} />
                    <TextBox label={strings['input-phone']} name='phone' type='tel' value={this.state.phone} onHandleChange={this.handleChange} errors={this.state.errors} />
                    <TextArea label={strings.feedback} name='feedback' rows={6} value={this.state.feedback} onHandleChange={this.handleChange} errors={this.state.errors} />
                    <SubmitButton label={strings['submit-button']} handleClick={this.onClick} />
                  </div>
                  <div className='powered-by-container feedback-powered-by'>
                    <PoweredBy />
                  </div>
                </div>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(state => ({ user: state.user, app: state.app }))(PageBar);
