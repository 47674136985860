import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './TextArea.scss';

export default class TextArea extends Component {
  render () {
    const { props } = this;
    return (
      <div className={'textarea-container ' + props.className}>
        <label>{props.label}</label>
        {props.errors[props.name].length > 0 && <span className='error'>{props.errors[props.name]}</span>}
        <textarea name={props.name} value={props.value} onChange={props.onHandleChange} rows={props.rows} />

      </div>
    );
  }
}

TextArea.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  errors: PropTypes.object,
  name: PropTypes.string,
  rows: PropTypes.number,
  className: PropTypes.string,
  onHandleChange: PropTypes.func
};
