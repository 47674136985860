import React, { Component } from 'react';

export default class FunduLogo extends Component {
  render () {
    return (
      <svg xmlns='http://www.w3.org/2000/svg' width='58' height='18' viewBox='0 0 58 18'>
        <g>
          <path d='M46.676 16.454H57.969V17.808H46.676zM.015.015L.015 14.453 3.084 14.453 3.084 8.558 8.516 8.558 8.516 5.803 3.084 5.803 3.084 2.832 9.099 2.832 9.099.015zM10.096 9.897V.015h3.07v9.728c0 1.863 1.211 2.201 2.285 2.201s2.287-.323 2.287-2.2V.014h3.068v9.882c0 3.971-3.16 4.833-5.355 4.833-2.179 0-5.355-.877-5.355-4.833M30.089.015L30.166 8.635 25.517.015 22.479.015 22.479 14.453 25.517 14.453 25.44 5.834 30.089 14.453 33.112 14.453 33.112.015zM37.93 11.636h1.657c2.24 0 2.9-1.616 2.9-4.402 0-2.77-.66-4.402-2.9-4.402H37.93v8.804zm-3.069 2.817V.015h4.665c4.88 0 6.137 3.125 6.137 7.22 0 4.094-1.273 7.218-6.137 7.218H34.86zM46.968 9.897V.015h3.069v9.728c0 1.863 1.212 2.201 2.286 2.201s2.286-.323 2.286-2.2V.014h3.069v9.882c0 3.971-3.161 4.833-5.355 4.833-2.194 0-5.355-.877-5.355-4.833' />
        </g>
      </svg>
    );
  }
}
