import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './SubmitButton.scss';

export default class SubmitButton extends Component {
  render () {
    const { props } = this;
    return (
      <div className='submit-button-container'>
        <button onClick={props.handleClick}>{props.label}</button>
      </div>
    );
  }
}

SubmitButton.propTypes = {
  label: PropTypes.string,
  handleClick: PropTypes.func
};
