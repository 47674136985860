import { handleActions } from 'redux-actions';
import { USER_SET_MODEL, USER_SET_INFO, USER_SET_RESULT } from './actions';

/**
 * User state
 */
export const userState = {
  model: {},
  info: {},
  result: {}
};

/**
 * User reducer
 */
export default handleActions({
  [USER_SET_MODEL]: (state, action) => ({
    ...state,
    model: action.payload
  }),

  [USER_SET_INFO]: (state, action) => ({
    ...state,
    info: action.payload
  }),

  [USER_SET_RESULT]: (state, action) => ({
    ...state,
    result: action.payload
  })
},
userState);
