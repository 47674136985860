import { handleActions } from 'redux-actions';
import { APP_SET_COMPUTING } from './actions';

/**
 * App state
 */
export const appState = {
  computing: false
};

/**
 * App reducer
 */
export default handleActions({
  [APP_SET_COMPUTING]: (state, action) => ({
    ...state,
    computing: action.payload
  })
},
appState);
