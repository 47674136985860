import React, { Component } from 'react';

export default class Back extends Component {
  render () {
    return (
      <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
        <path fill='#FFF' d='M22 10H6l5.17-6.79c.568-.877.366-2.043-.465-2.677C9.875-.101 8.696.01 8 .79l-7.62 10c-.543.715-.543 1.705 0 2.42l7.62 10c.685.822 1.896.96 2.748.311.851-.648 1.042-1.852.432-2.731L6 14h16c1.105 0 2-.895 2-2s-.895-2-2-2z' />
      </svg>
    );
  }
}
