import React from 'react';
import PropTypes from 'prop-types';

/**
 * Main Layout
 */
export default function Main ({ children }) {
  return (
    <div className='Main'>
      <section className='wrapper'>
        {children}
      </section>
    </div>
  );
}

Main.propTypes = {
  children: PropTypes.object
};
