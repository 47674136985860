import isFunction from 'lodash/isFunction';

/**
 * Bind methods
 */
export function bind (object, methods = []) {
  (methods || Object.getOwnPropertyNames(object.constructor.prototype)).forEach((key) => {
    if (key !== 'constructor' && isFunction(object[key])) {
      object[key] = object[key].bind(object);
    }
  });
  return object;
}
