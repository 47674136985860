import React, { Component } from 'react';
import FunduLogo from 'components/general/svg/FunduLogo';
import strings from 'locale/fi.json';
import './PoweredBy.scss';

export default class PoweredBy extends Component {
  render () {
    return (
      <div>
        <a target='_blank' rel='noopener noreferrer' href={strings['fundu-link']}>
          <span>{strings['powered-by']}</span>
          <FunduLogo />
        </a>
      </div>
    );
  }
}
