import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import user from 'store/user/reducers';
import app from 'store/app/reducers';

import { history } from '../routes';

const router = connectRouter(history);

export default combineReducers({
  user,
  app,
  router
});
